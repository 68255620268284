<template>
  <div class="page">
    <div class="selectCompany">
      <div class="selectCompany-left">选择公司</div>
      <div class="selectCompany-right" @click="show = true">
        <div class="text">{{ companyName || "请选择" }}</div>
        <img src="./img/arrow.png" alt="" />
      </div>
    </div>
    <div class="imageUpload">图片上传：</div>
    <v-upload
      class="oItem-b_upload"
      :imgUrl.sync="form.imageUrl1"
      :activityPicture.sync="form.imageUrl"
      :maxCount="3"
      ref="load"
    ></v-upload>
    <div class="eventLocation">
      <div class="eventLocation-left">事件地点：</div>
      <div class="eventLocation-right">
        <input
          v-model="form.visitingAddress"
          type="text"
          placeholder="请填写事件地点"
        />
      </div>
    </div>
    <div class="eventLocation" style="border: none">
      <div class="eventLocation-left">事件事项：</div>
    </div>
    <van-field
      style="background: #f7f8fa; height: 100%"
      v-model="form.content"
      type="textarea"
      maxlength="50"
      autosize
      row="3"
      placeholder="请输入走访事项内容"
      show-word-limit
    />
    <div class="btn" @click="submit()">提交</div>
    <v-picker
      :columns="companyList"
      :valueShow="show"
      valueKey="companyName"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import {
  getVisPerInfoByMobileUrl,
  deleteRecInfoUrl,
  getRecDetailsUrl,
  getRecInfoListUrl,
  getCompanyListUrl,
  addVisRecInfoUrl,
  userInfoUrl,
} from "./api.js";
import { mapState } from "vuex";
export default {
  name: "addMyVisit",
  data() {
    return {
      show: false,
      companyList: [],
      companyName: null,
      taxpayerRegistrationNumber: null,
      form: {
        imageUrl: null,
        visitingAddress: null,
        content: null,
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    submit() {
      if (!this.companyName) {
        this.$toast("请选择公司！");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.imageUrl) {
        this.$toast("请上传图片");
        return;
      }
      if (!this.form.visitingAddress) {
        this.$toast("请填写事件地点");
        return;
      }
      let params = {
        visitingId: sessionStorage.getItem("visitingId"),
        visitingMobile: sessionStorage.getItem("mobile"),
        taxpayerRegistrationNumber: this.taxpayerRegistrationNumber,
        companyName: this.companyName,
        imageUrl: this.form.imageUrl,
        visitingAddress: this.form.visitingAddress,
        content: this.form.content,
        userId: this.userId,
      };
      this.$axios
        .post(`${addVisRecInfoUrl}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$router.replace({
              name: "addMyVisitSuccess",
            });
          }
        });
    },
    getCompanyList() {
      let params = {
        visitingId: sessionStorage.getItem("visitingId"),
      };
      this.$axios
        .get(`${getCompanyListUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.companyList = res.data;
          }
        });
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      this.companyName = value.companyName;
      this.taxpayerRegistrationNumber = value.taxpayerRegistrationNumber;
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  padding: 0 43px;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9490ff 0%, #5f5bff 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .eventLocation {
    width: 100%;
    height: 100px;
    line-height: 100px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    .eventLocation-left {
      font-size: 32px;
      color: #333333;
    }
    .eventLocation-right {
      flex: 1;
      input {
        text-align: right;
        width: 100%;
        height: 100%;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .imageUpload {
    padding: 44px 10px;
    box-sizing: border-box;
    font-size: 32px;
    color: #333333;
  }
  .selectCompany {
    width: 100%;
    height: 100px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 32px;
    color: #333333;
    justify-content: space-between;
    .selectCompany-right {
      display: flex;
      align-items: center;
      .text {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);
      }
      img {
        margin-left: 14px;
        width: 12px;
        height: 20px;
      }
    }
  }
}
</style>
